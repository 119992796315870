import React, { useState } from 'react';
import './App.css';
import FormComponent from './FormComponent';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="site-name">
            配信文章生成くん
          </div>
          {/* <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
            &#9776;
          </div>
          <nav className={`menu ${menuOpen ? 'active' : ''}`}>
            <ul>
              <li><a href="#about">About</a></li>
              <li><a href="#service">サービス</a></li>
              <li><a href="#contact">お問い合わせ</a></li>
            </ul>
          </nav> */}
        </div>
      </header>
      <main>
        <FormComponent />
      </main>
    </div>
  );
}

export default App;
