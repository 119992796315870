import React, { useState } from "react";
import axios from "axios";
import "./FormComponent.css";

const Industries = ['飲食業','美容業','小売業'];
const Categories = ['食品','洋服','化粧品'];
const Purposes = ['キャンペーン','お誕生日','お知らせ'];
const Tones = ['フォーマル', 'カジュアル', 'フレンドリー'];

const FormComponent = () => {
  const [formData, setFormData] = useState({
    industry: "",
    category: "",
    purpose: "",
    tone: "",
    importantPoints: "",
    generatedText: "",
    editedText: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mergedFormData = `
    業種 or カテゴリー: ${formData.industry || formData.category || '未選択'}
    目的: ${formData.purpose || '未選択'}
    文章スタイル: ${formData.tone || '未選択'}
    伝えたい重要ポイント: ${formData.importantPoints || '未選択'}
  `;

  const handleGenerateText = async () => {
    setLoading(true);
    const prompt = `
      あなたはマーケティングのプロフェッショナルです。以下の詳細情報に基づいて、LINE公式アカウントで配信する効果的な文章を作成してください。読み手にとってわかりやすく、興味を引き、行動を促す内容を重視してください。
      業種: ${formData.industry}
      カテゴリー: ${formData.category}
      目的: ${formData.purpose}
      文章スタイル: ${formData.tone}
      伝えたい重要ポイント: ${formData.importantPoints}
      文章の長さは、読者に負担をかけずに重要な情報が伝わる適切なボリュームにしてください。文末に必ず行動を促すメッセージ（CTA）を含めてください。
    `;

    try {
      const response = await axios.post(
        "https://form-test.allinclub.jp/generate_text.php", {
          prompt
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      const aiGeneratedText = response.data.choices[0].message.content;
      setFormData({ ...formData, generatedText: aiGeneratedText });
    } catch (error) {
      console.error("テキスト生成エラー:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <form>
        <div className="ind-cat-select">
          <div class="form-group">
            <label>業種から選ぶ：</label>
            <div className="choice-chip-group" data-name="industry">
              {Industries.map((industry) => (
                <div
                  key={industry}
                  className={`choice-chip ${formData.industry === industry ? 'selected' : ''}`}
                  data-value={industry}
                  onClick={() => handleChange('industry', industry)}
                >
                  {industry}
                </div>
              ))}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="industry_other"
                placeholder="その他の場合入力..."
                onChange={(e) => handleChange('industry', e.target.value)}
              />
            </div>
          </div>
          <h3 className="or-text">もしくは</h3>
          <div class="form-group">
            <label>カテゴリーから選ぶ：</label>
            <div class="choice-chip-group" data-name="category">
              {Categories.map((category) => (
                <div
                  key={category}
                  className={`choice-chip ${formData.industry === category ? 'selected' : ''}`}
                  data-value={category}
                  onClick={() => handleChange('industry', category)}
                >
                  {category}
                </div>
              ))}
            </div>

            <div className="form-group">
              <input
                type="text"
                name="category_other"
                placeholder="その他の場合入力..."
                // value={formData.category}
                onChange={(e) => handleChange('industry', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="centered-form">
          <div class="form-group">
            <label>目的：</label>
            <div class="choice-chip-group" data-name="purpose">
              {Purposes.map((purpose) => (
                <div
                  key={purpose}
                  className={`choice-chip ${formData.purpose === purpose ? 'selected' : ''}`}
                  data-value={purpose}
                  onClick={() => handleChange('purpose', purpose)}
                >
                  {purpose}
                </div>
              ))}
            </div>

            <div className="form-group">
              <input
                type="text"
                name="purpose_other"
                placeholder="その他の場合入力..."
                onChange={(e) => handleChange('purpose', e.target.value)}
              />
            </div>
          </div>

          <div class="form-group">
            <label>文章スタイル：</label>
            <div class="choice-chip-group" data-name="tone">
              {Tones.map((tone) => (
                <div
                  key={tone}
                  className={`choice-chip ${formData.tone === tone ? 'selected' : ''}`}
                  data-value={tone}
                  onClick={() => handleChange('tone', tone)}
                >
                  {tone}
                </div>
              ))}
            </div>
          </div>

          <div className="form-group">
            <label>伝えたい重要ポイント（箇条書きでOK）：</label>
            <textarea
              name="importantPoints"
              value={formData.importantPoints}
              onChange={(e) => handleChange('importantPoints', e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>[文章生成条件の確認]　※編集不可</label>
            <textarea
              readOnly
              value={mergedFormData}
              rows="10"
              cols="50"
            />
          </div>
          <div className="button-container">
            <button type="button" onClick={handleGenerateText} disabled={loading}>
              {loading ? "生成中..." : "生成する"}
            </button>
          </div>

          <div className="form-group">
            <label>生成された配信文章：</label>
            <textarea
              name="editedText"
              value={formData.editedText || formData.generatedText}
              onChange={handleChange}
              rows="20"
              cols="50"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
